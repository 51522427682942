import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BasicModal from "../../../components/models/models";
import Header from "../../../components/Headers/Header";
import Table from "../../../components/Table/table";
import { heading } from "../../../services/teamsTableData";
import {
  updatedTeam,
  getEmployeesRequest,
  selectPersonalDashboard,
} from "../../../redux/slices/personalDashboardSlice";
import Image from "../../../assets/images/TeamUser.svg";
import PillCards from "./components/pill-cards";
import HighServicesCostCards from "./components/high-services-cost-card";
// import { formatedAmount } from "../../../utils/helpers";
import { selectUser } from "../../../redux/slices/userSlice";
import { current } from "@reduxjs/toolkit";
import {useFormattedAmount} from "../../../hooks/useFormattedAmount";

const initialState = {
  name: false,
  monthly_cost: false,
  services_count: false,
  team_cost: false,
  submissions_count: false,
  feedbacks_count: false,
  requests_count: false,
};

function TeamsModule({ show, setShow }) {
  const { user_id } = useParams();
  const containerDiv = useRef();
  const [fullName, setFullName] = useState("");
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [dashboard, setDashboard] = useState("");
  const [managerId, setManagerId] = useState(user_id);
  const [finalData, setFinalData] = useState();
  // const [totalTeamCost, setTotalTeamCost] = useState(0);
  // const [averageEmployeeCost, setAverageEmployeeCost] = useState(0);
  const [orderType, setOrderType] = useState("asc");
  const [btns, setBtns] = useState(initialState);
  const [active, setActive] = useState("monthly_cost");
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const { userDetails } = userData;
  const State = useSelector(selectPersonalDashboard);
  const {
    employeesData,
    managerName,
    totalTeamCost,
    averageTeamCost,
    totalRequests,
    totalSubmissions,
    totalFeedbacks,
    highCostServices,
  } = State;
  const formatFunction = useFormattedAmount();

  useEffect(() => {
    dispatch(getEmployeesRequest(user_id));
  }, [dispatch, user_id]);

  const handleNameChange = (newName) => {
    setFullName(newName);
  };

  useEffect(() => {
    const transformedData = transformResponse(employeesData);
    setFinalData(transformedData);
    containerDiv.current.scrollTop = 0;
    // const totalCost = employeesData?.reduce((sum, employee) => {
    //   return sum + (employee.monthly_cost || 0);
    // }, 0);
    // setTotalTeamCost(totalCost);
    // const presentEmployees = employeesData?.length;
    // const avgEmployeeCost = presentEmployees > 0 ? totalCost / presentEmployees : 0;
    // const formattedAvgEmployeeCost = avgEmployeeCost.toFixed(2);
    // setAverageEmployeeCost(formattedAvgEmployeeCost);
  }, [employeesData, managerName]);

  function transformResponse(response) {
    if (response === undefined) {
      return undefined;
    } else {
      return response?.map((item) => {
        return {
          id: item.id,
          employee: {
            image: item.profile_image_url ? item.profile_image_url : Image,
            name: item.name,
            work: item.distinction,
          },
          cost: formatFunction(item.monthly_cost),
          team_cost: formatFunction(item.team_cost), 
          // service: item.services_count,
          // submission: item.submissions_count,
          // request: item.requests_count,
          // feedback: item.feedbacks_count,
          also_manager: item.also_manager,
        };
      });
    }
  }

  const nameInitial = (name) => {
    return name.split(" ")[0];
  };

  const handleTeamName = (orderType, set) => {
    setBtns({
      ...btns,
      Employee: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "Employee" && active]: false,
    });
    setActive("Employee");
    let sort = null;
    if (orderType === "asc") {
      sort = [...employeesData].sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sort = [...employeesData].sort((a, b) => b.name.localeCompare(a.name));
    }
    dispatch(updatedTeam(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleTeamMonthlyCost = (orderType, set) => {
    setBtns({
      ...btns,
      monthly_cost: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "monthly_cost" && active]: false,
    });
    setActive("monthly_cost");
    let sort = null;
    orderType === "asc"
      ? (sort = [...employeesData].sort(
          (a, b) => a.monthly_cost - b.monthly_cost
        ))
      : (sort = [...employeesData].sort(
          (a, b) => b.monthly_cost - a.monthly_cost
        ));
    dispatch(updatedTeam(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleTeamService = (orderType, set) => {
    setBtns({
      ...btns,
      services_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "services_count" && active]: false,
    });
    setActive("services_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...employeesData].sort(
          (a, b) => a.services_count - b.services_count
        ))
      : (sort = [...employeesData].sort(
          (a, b) => b.services_count - a.services_count
        ));
    dispatch(updatedTeam(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  const handleTeamCost = (orderType, set) => {
    setBtns({
      ...btns,
      team_cost: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "team_cost" && active]: false,
    });
    setActive("team_cost");
    let sort = null;
    orderType === "asc"
      ? (sort = [...employeesData].sort((a, b) => a.team_cost - b.team_cost))
      : (sort = [...employeesData].sort((a, b) => b.team_cost - a.team_cost));
    dispatch(updatedTeam(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  // const handleTeamSubmission = (orderType, set) => {
  //   setBtns({
  //     ...btns,
  //     submissions_count: true,
  //     // upDown: set ? !btns.upDown : false,
  //     [active !== "submissions_count" && active]: false,
  //   });
  //   setActive("submissions_count");
  //   let sort = null;
  //   orderType === "asc"
  //     ? (sort = [...employeesData].sort((a, b) => a.submissions_count - b.submissions_count))
  //     : (sort = [...employeesData].sort((a, b) => b.submissions_count - a.submissions_count));
  //     dispatch(updatedTeam(sort));
  //   setOrderType(orderType == "asc" ? "desc" : "asc")
  // };
  // const handleTeamRequest = (orderType, set) => {
  //   setBtns({
  //     ...btns,
  //     requests_count: true,
  //     // upDown: set ? !btns.upDown : false,
  //     [active !== "requests_count" && active]: false,
  //   });
  //   setActive("requests_count");
  //   let sort = null;
  //   orderType === "asc"
  //     ? (sort = [...employeesData].sort((a, b) => a.requests_count - b.requests_count))
  //     : (sort = [...employeesData].sort((a, b) => b.requests_count - a.requests_count));
  //     dispatch(updatedTeam(sort));
  //   setOrderType(orderType == "asc" ? "desc" : "asc")
  // };
  // const handleTeamFeedback = (orderType, set) => {
  //   setBtns({
  //     ...btns,
  //     feedbacks_count: true,
  //     // upDown: set ? !btns.upDown : false,
  //     [active !== "feedbacks_count" && active]: false,
  //   });
  //   setActive("feedbacks_count");
  //   let sort = null;
  //   orderType === "asc"
  //     ? (sort = [...employeesData].sort((a, b) => a.feedbacks_count - b.feedbacks_count))
  //     : (sort = [...employeesData].sort((a, b) => b.feedbacks_count - a.feedbacks_count));
  //     dispatch(updatedTeam(sort));
  //   setOrderType(orderType == "asc" ? "desc" : "asc")
  // };

  // useEffect(() => {
  //   if (user_id === "1") {
  //     setFullName("");
  //   }
  // }, [user_id]);

  return (
    <div
      className="right_panel flex flex-col w-[100%] gap-[42px] pt-[20px] xl:pt-[32px] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]"
      ref={containerDiv}
    >
      {managerName && (
        <Header
          // title={`${fullName ? fullName + "'s Team" : 'Your Team'}`}
          title={`${managerName + "'s Team"}`}
          show={show}
          setShow={setShow}
          btns={false}
        />
      )}
      {!managerName && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          <div className="pt-[30px]">
            <Skeleton className="!w-[200px] !h-[48px]" />
          </div>
        </SkeletonTheme>
      )}

      <>
        <PillCards
          setState={setState}
          setPopup={setPopup}
          userId={user_id}
          userDetails={userDetails}
          setDashboard={setDashboard}
          totalTeamCost={totalTeamCost}
          averageEmployeeCost={averageTeamCost}
          totalRequests={totalRequests}
          totalFeedbacks={totalFeedbacks}
          totalSubmissions={totalSubmissions}
        />

        <HighServicesCostCards
          user_id={user_id}
          highCostServices={highCostServices}
        />

        <div className="card-section mb-4">
          <div className="cards_wrap double_space bg-[#363D50] w-[100%] rounded-[24px] overflow-y-scroll scrollbar-hide max-h-[470px] md:max-h-[670px]">
            <Table
              headings={heading}
              tableData={finalData}
              table={"team"}
              fullName={fullName}
              setFullName={setFullName}
              setManagerId={setManagerId}
              handleNameChange={handleNameChange}
              orderType={orderType}
              handleTeamName={handleTeamName}
              handleTeamMonthlyCost={handleTeamMonthlyCost}
              handleTeamService={handleTeamService}
              handleTeamCost={handleTeamCost}
              // handleTeamSubmission={handleTeamSubmission}
              // handleTeamRequest={handleTeamRequest}
              // handleTeamFeedback={handleTeamFeedback}
            />
          </div>
        </div>
      </>

      {popup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          empId={user_id}
          dashboard={dashboard}
        />
      )}
    </div>
  );
}

export default TeamsModule;
