import React from "react";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFormattedAmount } from "../../../hooks/useFormattedAmount";
function ExpensesTableBody({ tableData, setState, setPopup, setDataPlanId }) {
  const formatFunction = useFormattedAmount();
  return (
    <tbody>
      {tableData &&
        tableData.map((row, index) => (
          <tr key={index} className="rounded-[24px]">
            {Object.keys(row)
              .filter((item) => item !== "id" && item !== "actual_cost")
              .map((item, index2) => (
                <td
                  key={index2}
                  className={cn(
                    "text-secondary !text-[#FFFFFF] px-[16px] py-[20px]",
                    {
                      "!px-[10px] w-[15%]": item === "name",
                    }
                  )}
                >
                  {(item === "overage_totals" ||
                    item === "roaming_totals" ||
                    item === "other_costs") &&
                    formatFunction(row[item])}
                  {(item === "name" || item === "plan_used") && row[item]}
                  {item === "contractual_cost" &&
                    `${formatFunction(row[item])}/${formatFunction(
                      row["actual_cost"]
                    )}`}
                </td>
              ))}
            <td
              onClick={() => {
                setPopup(true);
                setState(58);
                setDataPlanId(row.id)
              }}
              className="cursor-pointer px-[16px] py-[20px] !text-[#FFD74A] text-right text-sm font-bold"
            >
              View
            </td>
          </tr>
        ))}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3, 4, 5].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default ExpensesTableBody;
