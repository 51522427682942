import React from "react";
import Card from "../../../../components/card/feedbackerCards/card";
import { useLocation } from "react-router-dom";
import { useFormattedAmount } from "../../../../hooks/useFormattedAmount";

function UsageReportCards({ data }) {
  const { pathname } = useLocation();
  const formatFunction = useFormattedAmount();
  return (
    <div className="flex flex-col gap-[9px]">
      <div className="flex flex-col gap-[4px] pb-[16px]">
        <span className="text-primary !text-[24px] !leading-[36px]">
          Service usage reports
        </span>
        <span className="text-secondary !leading-[21px]">
          Reports for contracted services this user is opted-in to
        </span>
      </div>
      <div className="">
        <div className="cards_wrapper flex flex-wrap gap-[32px] justify-center items-center px-[5px] pt-[5px] overflow-scroll scrollbar-hide h-[auto] max-h-[540px] md:justify-center md:max-h-[760px] mdl:max-h-[920px] pb-[33px] lg:max-h-[575px] xl:justify-between xl:max-h-[760px] 2xl:px-0 3xl:max-h-[690px] 4xl:max-h-[660px] 5xl:max-h-[680px] 6xl:max-h-[820px]">
          {data?.service_usage_report &&
            data?.service_usage_report
              ?.filter((each) => each.service_name !== "Personal Expenses")
              .map((service, index) => (
                <Card
                  key={index}
                  image={service.image_url}
                  title={service.service_name}
                  price={formatFunction(service.cost_per_month)}
                  rating={service.rating}
                  btns={false}
                  navLink={`/${
                    pathname.includes("personal_dashboard")
                      ? "personal_dashboard"
                      : "company_dashboard"
                  }/${
                    pathname.includes("home")
                      ? "home/employee/usage_report"
                      : pathname.includes("personal_dashboard")
                      ? "teams/employee/usage_report"
                      : "employees/service/employee_report"
                  }/${service.id}?dataId=${data.id}`}
                />
              ))}
          {!data && [1, 2, 3, 4, 5, 6].map((el, index) => <Card key={index} />)}
        </div>
      </div>
    </div>
  );
}

export default UsageReportCards;
