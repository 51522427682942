import React from "react";
import { Link } from "react-router-dom";

function SuccessResetPassword() {
  return (
    <div
      className="flex flex-col items-center min-h-screen px-5 sm:px-0"
      style={{
        background: "linear-gradient(129.77deg, #2C3345 26.01%, #323045 84.1%)",
      }}
    >
      <div className="flex flex-col items-center gap-4 min-w-[424px] w-[28%] mt-[200px]">
        <p className="text-[32px] leading-[48px] font-semibold text-white">
          Password reset
        </p>
        <p className="text-secondary text-center">
          We’ve successfully changed your password. You can now log in.
        </p>
        <Link to="/login" className="flex items-center gap-2 py-1.5">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.2771 4.29434C16.7457 4.76297 16.7457 5.52277 16.2771 5.9914L10.4828 11.7857L16.2771 17.5801C16.7457 18.0487 16.7457 18.8085 16.2771 19.2771C15.8085 19.7457 15.0487 19.7457 14.58 19.2771L8.78571 13.4828C7.84845 12.5455 7.84845 11.0259 8.78571 10.0887L14.58 4.29434C15.0487 3.82571 15.8085 3.82571 16.2771 4.29434Z"
              fill="#FFD74A"
            />
          </svg>
          <span className="text-sm font-bold leading-[21px] text-[#FFD74A]">
            Back to log in
          </span>
        </Link>
      </div>
    </div>
  );
}

export default SuccessResetPassword;
