import React, { useEffect, useState } from "react";
import BasicModal from "../../../components/models/models";
import PlanUsageCard from "./components/plan-usage-card";
import FeedbackAndRequestServiceCards from "./components/feedback-and-request-service-cards";
import Header from "./components/header";
import PillCards from "./components/pill-cards";
import ServicesRequestAndFeedbackCards from "./components/services-request-and-feedback-cards";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsageReportRequest,
  selectPersonalDashboard,
} from "../../../redux/slices/personalDashboardSlice";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../redux/slices/userSlice";

function UsageReportModule({ path }) {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [userServiceId, setuserServiceId] = useState();
  const [reqId, setReqId] = useState();
  const [dashboard, setDashboard] = useState();
  const [feedbackID, setFeedbackID] = useState();
  const State = useSelector(selectPersonalDashboard);
  const { usageReportData, servicesData } = State;
  const dataPlans = usageReportData.data_plans
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userDetails } = useSelector(selectUser);

  useEffect(() => {
    dispatch(getUsageReportRequest(id));
  }, [id, popup, dispatch]);

  return (
    <div className="right_panel relative flex flex-col pt-[16px] w-[100%] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]">
      <Header path={path} data={usageReportData} />
      <div className="card-section">
        <div className="cards_wrap flex flex-col gap-[40px] h-[100vh] lg:h-[680px] pt-8 !pb-[44px] overflow-y-scroll scrollbar-hide">
          <PillCards
            data={usageReportData}
            servicesData={servicesData}
            setPopup={setPopup}
            modalId={id}
            setState={setState}
            userDetails={userDetails}
          />

          <FeedbackAndRequestServiceCards
            setId={setuserServiceId}
            data={usageReportData}
            setPopup={setPopup}
            setState={setState}
          />

          {usageReportData?.service?.category_name === "Mobile Phone" && (
            <PlanUsageCard dataPlans={dataPlans}/>
          )}

          <ServicesRequestAndFeedbackCards
            data={usageReportData}
            setFeedbackID={setFeedbackID}
            setPopup={setPopup}
            setState={setState}
            setReqId={setReqId}
            setDashboard={setDashboard}
          />
        </div>
      </div>

      {setPopup && (
        <BasicModal
          id={{
            serviceId: usageReportData?.service?.id,
            userId: usageReportData?.user?.id,
            feedbackId: feedbackID,
            id: usageReportData?.service?.id,
            reqid: reqId,
          }}
          feedid={{ id: usageReportData?.service?.id, feedbackId: feedbackID }}
          feedBackId={feedbackID}
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          dashboard={dashboard}
        />
      )}
    </div>
  );
}

export default UsageReportModule;
