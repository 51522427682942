import React, { useEffect, useState } from "react";
import BasicModal from "../../../../components/models/models";
import Header from "./components/header";
import NegativeFeedbackCard from "./components/negative-feedback-card";
import PillCards from "./components/pill-cards";
import PlanUsageCard from "./components/plan-usage-card";
import RequestAndUserCostCards from "./components/request-and-user-cost-cards";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsageReportData,
  selectCompanyDashboard,
  getYearlyUsegesServiceData,
} from "../../../../redux/slices/companyDashboardSlice";
import {
  selectPersonalDashboard,
  getSavingOpportunity,
} from "../../../../redux/slices/personalDashboardSlice";
import { useParams } from "react-router-dom";
import Button from "./../../../../components/Buttons/button";
import robotArms from "../../../../assets/images/robotArms.png";
import SavingOpportunity from "../../../../components/opportunity/SavingOpportunity";

function ServicesModule({ show, setShow, path }) {
  const [popup, setPopup] = useState(false);
  const [feedServiceId, setFeedServiceID] = useState();
  const [state, setState] = useState(0);
  const [feedbackID, setFeedbackID] = useState();
  const [dataPlanId, setDataPlanId] = useState();
  const [fedbackidserve, setfedbackidserve] = useState();
  const [dashboard, setDashboard] = useState("");
  const [manageId, setManageId] = useState("");
  const [tab, setTab] = useState();

  const State = useSelector(selectCompanyDashboard);
  const { usageReportData, manageServicesData, YearlyUsegeServiceData } = State;
  const personal_state = useSelector(selectPersonalDashboard);
  const { savingOpportunityData } = personal_state;
  const dispatch = useDispatch();
  const { id } = useParams();
  const serviceId = usageReportData?.service?.id;
  const setupServices = usageReportData?.setup_services;
  const dataPlans = usageReportData?.data_plans
  const showFinishSetupButton =
    setupServices && setupServices.some((service) => service.id === serviceId);
  const reportMissingMonth =
    YearlyUsegeServiceData?.missing_monthly_result?.month;
  const reportDay = manageServicesData.monthly_report_day;

  useEffect(() => {
    if (id) {
      dispatch(getUsageReportData(id));
    }
  }, [id, popup]);

  useEffect(() => {
    if (setupServices && setupServices.length > 0) {
      setManageId(serviceId);
    }
  }, [setupServices, serviceId]);

  useEffect(() => {
    if (id) {
      dispatch(getSavingOpportunity(id, "service_id"));
    }
  }, [id]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    dispatch(getYearlyUsegesServiceData(id, currentYear));
  }, [id]);

  return (
    <div className="right_panel relative w-[100%] flex flex-col px-[20px] lg:px-[32px] pt-[0px]">
      <Header
        show={show}
        setShow={setShow}
        path={path}
        data={usageReportData?.service}
        owner_authorized={usageReportData?.owner_authorized}
        setPopup={setPopup}
        setManageId={setManageId}
        setState={setState}
        setupServices={showFinishSetupButton}
        setTab={setTab}
        reportMissingMonth={reportMissingMonth}
        reportDay={reportDay}
      />

      {showFinishSetupButton ? (
        <div className="card-section">
          <div className="cards_wrap inner_space flex flex-col gap-[40px] h-[100vh] lg:h-[calc(100vh-135px)] overflow-y-scroll scrollbar-hide home_module">
            <div className="imgWrapper">
              <img src={robotArms} alt="img" className="boxImg" />
              <div className="boxHeading">Ready this service for reporting</div>
              <div className="boxText">
                Finish setup for this service so thryft can use it for report
                generation on <span className="date">00/00/00</span>.
              </div>
              <Button
                onClick={() => {
                  setPopup(true);
                  setState(32);
                }}
                primary
                fill
                hoverColor
              >
                Finish setup
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="card-section">
          <div className="cards_wrap flex flex-col gap-[32px] h-[100vh] lg:h-[680px] pt-[32px] !pb-[37px] overflow-y-scroll scrollbar-hide">
            {savingOpportunityData?.savings_opportunities?.length > 0 && (
              <SavingOpportunity
                opportunity={savingOpportunityData}
                // userId={userDetails?.user?.id}
              />
            )}

            <PillCards
              data={usageReportData}
              id={id}
              serviceId={serviceId}
              setPopup={setPopup}
              setState={setState}
              setFeedServiceID={setFeedServiceID}
              setDashboard={setDashboard}
            />

            {usageReportData?.service?.category_name === "Mobile Phone" && (
              <PlanUsageCard
                setPopup={setPopup}
                setState={setState}
                dataPlans={dataPlans}
                setDataPlanId={setDataPlanId}
              />
            )}

            <RequestAndUserCostCards
              data={usageReportData}
              setPopup={setPopup}
              setState={setState}
              setDashboard={setDashboard}
              setfedbackidserve={setfedbackidserve}
              id={id}
              serviceId={serviceId}
            />

            {usageReportData?.service?.name !== "Personal Expenses" && (
              <NegativeFeedbackCard
                setFeedbackID={setFeedbackID}
                feedback={usageReportData?.negative_feedback}
                setPopup={setPopup}
                id={id}
                setState={setState}
                serviceId={serviceId}
                setDashboard={setDashboard}
              />
            )}
          </div>
        </div>
      )}

      {popup && (
        <BasicModal
          activeTab={tab}
          feedBackId={feedbackID}
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          id={(fedbackidserve && fedbackidserve) || manageId}
          RequestserviceID={serviceId}
          feedServiceId={feedServiceId}
          serviceId={serviceId}
          dashboard={dashboard}
          feedid={{ id: id, feedbackId: feedbackID }}
          dataPlanId={dataPlanId}
          dataPlans={dataPlans}
        />
      )}
    </div>
  );
}

export default ServicesModule;
