import React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Button from "../../../../../components/Buttons/button";
import {
  getUsageFeedbackData,
  getUsageRequestData,
  getUsageServiceData,
} from "../../../../../redux/slices/companyDashboardSlice";
// import { formatedAmount } from "../../../../../utils/helpers";
import { useFormattedAmount } from "../../../../../hooks/useFormattedAmount";
import { findMaxCountOfReview } from "../lib/findMaxCountOfReview";

import negativeIcon from "../../../../../assets/images/negative.png";
import negativeHoverIcon from "../../../../../assets/images/nagative_hover.png";
import neutralIcon from "../../../../../assets/images/neutral.png";
import neutralHoverIcon from "../../../../../assets/images/neutral_hover.png";
import smileyIcon from "../../../../../assets/images/smiley.png";
import smileyHoverIcon from "../../../../../assets/images/smiley_hover.png";

function PillCards({
  setPopup,
  setState,
  data,
  id,
  serviceId,
  setFeedServiceID,
  setDashboard,
}) {
  const maxCountOfReviews = findMaxCountOfReview(data);

  const dispatch = useDispatch();
  const getServiceRequest = (id) => {
    dispatch(getUsageRequestData(id));
  };
  const formatFunction = useFormattedAmount();
  return (
    <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
      <div className="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 justify-center xl:justify-between items-center gap-[32px]">
        <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] bg-[#363D50] rounded-[32px]">
          <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
            {data?.monthly_service_cost !== undefined && (
              <span className="text-primary !text-[18px] block">
                {formatFunction(data?.monthly_service_cost)}
              </span>
            )}
            {data?.monthly_service_cost === undefined && (
              <Skeleton className="!w-[132px]" />
            )}
            <span className="text-secondary !leading-[21px] mt-auto">
              Monthly service cost
            </span>
          </div>
        </div>

        <div className="flex flex-col justify-center w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] bg-[#363D50] rounded-[32px]">
          {data?.user_cost_average !== undefined && (
            <span className="text-primary !text-[18px] block">
              {formatFunction(data?.user_cost_average)}
            </span>
          )}
          {data?.user_cost_average === undefined && (
            <Skeleton className="!w-[132px]" />
          )}
          <span className="text-secondary !leading-[21px] mt-auto">
            User cost average
          </span>
        </div>

        {data?.service?.name !== "Personal Expenses" && (
          <div className="flex flex-col justify-center w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] bg-[#363D50] rounded-[32px]">
            <div className="flex w-[273px] h-[24px] gap-[16px]">
              <div className="flex gap-[6px] items-center">
                {data?.positive_ratings_count === maxCountOfReviews ? (
                  <img
                    src={smileyHoverIcon}
                    alt="rating-img"
                    className="w-[28px] h-[28px] rating_icon active"
                  />
                ) : (
                  <img
                    src={smileyIcon}
                    alt="rating-img"
                    className="w-[28px] h-[28px] rating_icon inactive"
                  />
                )}
                <span className="text-secondary !text-[#A5AEBC] !leading-[21px]">
                  {data?.positive_ratings_count}
                </span>
              </div>

              <div className="flex gap-[6px] items-center">
                {data?.general_ratings_count === maxCountOfReviews ? (
                  <img
                    src={neutralHoverIcon}
                    alt="rating-img"
                    className="w-[28px] h-[28px] rating_icon active"
                  />
                ) : (
                  <img
                    src={neutralIcon}
                    alt="rating-img"
                    className="w-[28px] h-[28px] rating_icon inactive"
                  />
                )}
                <span className="text-secondary !text-[#A5AEBC] !leading-[21px]">
                  {data?.general_ratings_count}
                </span>
              </div>

              <div className="flex gap-[6px] items-center">
                {data?.negative_ratings_count === maxCountOfReviews ? (
                  <img
                    src={negativeHoverIcon}
                    alt="rating-img"
                    className="w-[28px] h-[28px] rating_icon active"
                  />
                ) : (
                  <img
                    src={negativeIcon}
                    alt="rating-img"
                    className="w-[28px] h-[28px] rating_icon inactive"
                  />
                )}
                <span className="text-secondary !text-[#A5AEBC] !leading-[21px]">
                  {data?.negative_ratings_count}
                </span>
              </div>
            </div>
            <span className="text-secondary !leading-[21px]">
              Impact rating
            </span>
          </div>
        )}

        <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
          <div className="flex items-center gap-4">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9993 12.5001C18.2085 12.5001 19.9993 10.7092 19.9993 8.50008C19.9993 6.29094 18.2085 4.50008 15.9993 4.50008C13.7902 4.50008 11.9993 6.29094 11.9993 8.50008C11.9993 10.7092 13.7902 12.5001 15.9993 12.5001ZM15.9993 13.8334C18.9449 13.8334 21.3327 11.4456 21.3327 8.50008C21.3327 5.55456 18.9449 3.16675 15.9993 3.16675C13.0538 3.16675 10.666 5.55456 10.666 8.50008C10.666 11.4456 13.0538 13.8334 15.9993 13.8334Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 24.5002C6 19.7137 9.8802 15.8335 14.6667 15.8335H17.3333C22.1198 15.8335 26 19.7137 26 24.5002V25.8335C26 27.6744 24.5076 29.1668 22.6667 29.1668H9.33333C7.49238 29.1668 6 27.6744 6 25.8335V24.5002ZM14.6667 17.1668C10.6166 17.1668 7.33333 20.4501 7.33333 24.5002V25.8335C7.33333 26.9381 8.22876 27.8335 9.33333 27.8335H22.6667C23.7712 27.8335 24.6667 26.9381 24.6667 25.8335V24.5002C24.6667 20.4501 21.3834 17.1668 17.3333 17.1668H14.6667Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="flex flex-col gap-[6px] h-[51px]">
              {data?.service_users_count && (
                <span className="text-primary !text-[18px]">
                  {data?.service_users_count}
                </span>
              )}
              {data?.service_users_count === undefined && (
                <Skeleton className="!w-[132px]" />
              )}
              <span className="text-secondary !leading-[21px]">
                Service users
              </span>
            </div>
          </div>
          <Button
            // className={cn({
            //   'invisible': !data?.service_users_count
            // })}
            onClick={() => {
              setPopup(true);
              setState(19);
              dispatch(getUsageServiceData(id));
            }}
            secondary
            hoverColor
          >
            View
          </Button>
        </div>

        {data?.service?.name !== "Personal Expenses" && (
          <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
            <div className="flex items-center gap-4">
              <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.66634 9.33333C4.66634 8.22876 5.56177 7.33333 6.66634 7.33333H25.333C26.4376 7.33333 27.333 8.22876 27.333 9.33333V20C27.333 21.1046 26.4376 22 25.333 22H19.7709C18.8869 22 18.039 22.3512 17.4139 22.9763L12.1949 28.1953C11.9346 28.4556 11.9346 28.8777 12.1949 29.1381C12.4553 29.3984 12.8774 29.3984 13.1377 29.1381L18.3567 23.9191C18.7318 23.544 19.2405 23.3333 19.7709 23.3333H25.333C27.174 23.3333 28.6663 21.8409 28.6663 20V9.33333C28.6663 7.49238 27.174 6 25.333 6H6.66634C4.82539 6 3.33301 7.49238 3.33301 9.33333V20C3.33301 21.8409 4.82539 23.3333 6.66634 23.3333H11.333C11.7012 23.3333 11.9997 23.0349 11.9997 22.6667C11.9997 22.2985 11.7012 22 11.333 22H6.66634C5.56177 22 4.66634 21.1046 4.66634 20V9.33333ZM10.6663 14C10.2982 14 9.99967 14.2985 9.99967 14.6667C9.99967 15.0349 10.2982 15.3333 10.6663 15.3333H10.6797C11.0479 15.3333 11.3463 15.0349 11.3463 14.6667C11.3463 14.2985 11.0479 14 10.6797 14H10.6663ZM15.9997 14C15.6315 14 15.333 14.2985 15.333 14.6667C15.333 15.0349 15.6315 15.3333 15.9997 15.3333H16.013C16.3812 15.3333 16.6797 15.0349 16.6797 14.6667C16.6797 14.2985 16.3812 14 16.013 14H15.9997ZM21.333 14C20.9648 14 20.6663 14.2985 20.6663 14.6667C20.6663 15.0349 20.9648 15.3333 21.333 15.3333H21.3463C21.7145 15.3333 22.013 15.0349 22.013 14.6667C22.013 14.2985 21.7145 14 21.3463 14H21.333Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="flex flex-col gap-[6px] h-[51px]">
                {data?.requests_count && (
                  <span className="text-primary !text-[18px]">
                    {data?.requests_count}
                  </span>
                )}
                {data?.requests_count === undefined && (
                  <Skeleton className="!w-[132px]" />
                )}
                <span className="text-secondary !leading-[21px]">Requests</span>
              </div>
            </div>
            <Button
              // className={cn({
              //   'invisible': !data?.requests_count
              // })}
              onClick={() => {
                setPopup(true);
                setState(20);
                setDashboard("companyDahboard");
                getServiceRequest(id);
              }}
              secondary
              hoverColor
            >
              View
            </Button>
          </div>
        )}

        {data?.service?.name !== "Personal Expenses" && (
          <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
            <div className="flex items-center gap-4">
              <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.91063 9.66914C10.727 9.16154 11.7229 9.03114 12.6424 9.3114L19.3491 11.3554C20.2688 11.6357 20.7217 12.6703 20.3039 13.5362C19.9788 14.2099 19.223 14.56 18.4989 14.3725L15.1335 13.5006C15.0657 13.4823 14.9972 13.4752 14.9301 13.4783C14.728 13.4874 14.5407 13.5888 14.4222 13.7507C14.3787 13.8101 14.3445 13.8777 14.3222 13.9519C14.2997 14.026 14.2909 14.1013 14.2943 14.1748C14.3065 14.4446 14.483 14.6879 14.7485 14.7766L17.4288 15.7024C18.321 16.0105 19.2856 16.0409 20.1954 15.7895L27.3649 13.8084C27.8684 13.6693 28.4053 13.8576 28.7115 14.2807C29.1229 14.8491 28.9865 15.6446 28.4092 16.0435L19.9792 21.8671C19.1671 22.4281 18.1482 22.6003 17.1968 22.3374L9.8707 20.313C9.46585 20.2012 9.04776 20.1445 8.62774 20.1445H5.31011C4.94192 20.1445 4.64345 20.4429 4.64345 20.8111C4.64345 21.1793 4.94192 21.4778 5.31011 21.4778H8.62774C8.92776 21.4778 9.22639 21.5183 9.51557 21.5982L16.8417 23.6226C18.1736 23.9907 19.6001 23.7495 20.7371 22.9641L29.167 17.1405C30.3596 16.3166 30.6415 14.6732 29.7917 13.4989C29.159 12.6248 28.0499 12.2358 27.0098 12.5232L21.5426 14.0339C22.2493 12.4487 21.4109 10.5899 19.7378 10.08L13.0312 8.03599C11.7437 7.64362 10.3496 7.82618 9.20662 8.53682L4.31475 11.5783C4.00207 11.7727 3.90619 12.1838 4.1006 12.4965C4.295 12.8091 4.70608 12.905 5.01876 12.7106L9.91063 9.66914Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="flex flex-col gap-[6px] h-[51px]">
                {data?.feedbacks_count && (
                  <span className="text-primary !text-[18px]">
                    {data?.feedbacks_count}
                  </span>
                )}
                {data?.feedbacks_count === undefined && (
                  <Skeleton className="!w-[132px]" />
                )}
                <span className="text-secondary !leading-[21px]">Feedback</span>
              </div>
            </div>
            <Button
              // className={cn({
              //   'invisible': !data?.feedbacks_count
              // })}
              onClick={() => {
                setPopup(true);
                setState(21);
                dispatch(getUsageFeedbackData(id));
                setFeedServiceID(serviceId);
                setDashboard("companyDahboardfeedback");
              }}
              secondary
              hoverColor
            >
              View
            </Button>
          </div>
        )}
      </div>
    </SkeletonTheme>
  );
}

export default PillCards;
