import React, { useState, useEffect } from "react";
import ManageAlert from "../components/ManageAlert";
import Button from "../../../../../components/Buttons/button";
import BasicModal from "../../../../models/models";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceContract,
  setServiceContractFasle,
  deleteContract,
  selectCompanyDashboard,
} from "../../../../../redux/slices/companyDashboardSlice";
import deleteIcon from "../../../../../assets/images/bin.png";
import editIcon from "../../../../../assets/images/icon_edit.png";

const ContractData = ({ service_id }) => {
  const dispatch = useDispatch();
  const [alertClose, setAlertClose] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [state, setState] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const State = useSelector(selectCompanyDashboard);
  const [contractId, setContractId] = useState("");
  const { ServiceContractData, createContract } = State;

  const handleUpload = () => {
    setPopUp(true);
    setState(51);
  };

  useEffect(() => {
    dispatch(setServiceContractFasle());
  }, [createContract]);

  useEffect(() => {
    dispatch(getServiceContract(service_id));
  }, [confirmDelete, createContract]);

  useEffect(() => {
    if (confirmDelete && contractId) {
      dispatch(deleteContract(contractId));
    }
    setConfirmDelete(false);
    setPopUp(false);
  }, [confirmDelete]);

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { month: "short", year: "numeric" });
  };

  const handleDelete = (contract_id) => {
    setContractId(contract_id);
    setPopUp(true);
    setState(52);
  };

  const handleEdit = (contract_id) => {
    setContractId(contract_id);
    setPopUp(true);
    setState(54);
  };

  return (
    <div className="showDatePanel">
      <div
        className={`${
          !alertClose &&
          "manage-divider px-[16px] py-[12px] h-[88px] flex items-center"
        }`}
      >
        <ManageAlert close={alertClose} setClose={setAlertClose} />
      </div>
      <div className="flex justify-end items-center pt-4 pr-[38px]">
        <Button
          onClick={handleUpload}
          outlined={true}
          hoverColor={true}
          secondary={false}
          secondaryHoverOutlined={true}
          fill={true}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 20.3965C4.61929 20.3965 3.5 19.2772 3.5 17.8965L3.5 14.8965C3.5 14.6203 3.72386 14.3965 4 14.3965C4.27614 14.3965 4.5 14.6203 4.5 14.8965L4.5 17.8965C4.5 18.7249 5.17157 19.3965 6 19.3965L18 19.3965C18.8284 19.3965 19.5 18.7249 19.5 17.8965L19.5 14.8965C19.5 14.6203 19.7239 14.3965 20 14.3965C20.2761 14.3965 20.5 14.6203 20.5 14.8965L20.5 17.8965C20.5 19.2772 19.3807 20.3965 18 20.3965L6 20.3965ZM12 15.3965C11.7239 15.3965 11.5 15.1726 11.5 14.8965L11.5 5.10359L8.35355 8.25004C8.15829 8.4453 7.84171 8.4453 7.64645 8.25004C7.45118 8.05478 7.45118 7.73819 7.64645 7.54293L11.2929 3.89648C11.6834 3.50596 12.3166 3.50596 12.7071 3.89648L16.3536 7.54293C16.5488 7.73819 16.5488 8.05477 16.3536 8.25004C16.1583 8.4453 15.8417 8.4453 15.6464 8.25004L12.5 5.10359L12.5 14.8965C12.5 15.1726 12.2761 15.3965 12 15.3965Z"
              fill="#000000"
            />
          </svg>
          Upload a new contract
        </Button>
      </div>
      <div className="panelContent mt-1">
        <div className="detailsSection">
          {ServiceContractData?.contract?.map((contract) => (
            <div className="py-[0px] px-thirty_two">
              <div className="card-animate w-[100%] py-[16px] px-[10px] border-[#41485C] hover:rounded-[20px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-[14px]">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#242B3E" />
                      <path
                        d="M17.9707 8H15C14.2044 8 13.4413 8.31607 12.8787 8.87868C12.3161 9.44129 12 10.2044 12 11V29C12 29.7957 12.3161 30.5587 12.8787 31.1213C13.4413 31.6839 14.2044 32 15 32H27C27.7956 32 28.5587 31.6839 29.1213 31.1213C29.6839 30.5587 30 29.7957 30 29V20M17.9707 8C19.628 8 21 9.34267 21 11V14C21 14.7956 21.3161 15.5587 21.8787 16.1213C22.4413 16.6839 23.2044 17 24 17H27C27.7956 17 28.5587 17.3161 29.1213 17.8787C29.6839 18.4413 30 19.2044 30 20M17.9707 8C22.8907 8 30 15.1467 30 20"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="flex flex-col">
                      <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                        {formatDate(contract.start_date)} -{" "}
                        {formatDate(contract.end_date)}
                      </span>
                      <span className="text-secondary !leading-[20px] !text-left">
                        Updated{" "}
                        {new Date(contract.updated_date).toLocaleDateString(
                          "en-GB"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-[18px]">
                    {contract?.renewal_date && (
                      <div className="flex items-center gap-2">
                        <span className="text-secondary !leading-[20px] !text-left bg-[#434A5D] px-[10px] py-[3px] rounded-[8px] text-[#939DAD] text-[12px] font-semibold">
                          REN
                        </span>
                        {new Date(contract?.renewal_date).toLocaleDateString(
                          "en-GB"
                        )}
                      </div>
                    )}
                    <Button
                      secondary={true}
                      hoverColor={true}
                      onClick={() => handleEdit(contract.id)}
                    >
                      Edit
                    </Button>
                    <Button
                      secondary={true}
                      hoverColor={true}
                      onClick={() => handleDelete(contract.id)}
                    >
                      Delete
                    </Button>                    
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {popUp && (
        <BasicModal
          serviceId={service_id}
          state={state}
          popup={popUp}
          setPopup={setPopUp}
          setState={setState}
          id={contractId}
          setConfirmDelete={setConfirmDelete}
        />
      )}
    </div>
  );
};

export default ContractData;
