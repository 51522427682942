import React, { useEffect, useState, useRef } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Select from "../../../../Select/select";
import TextField from "../../../../TextField/TextField";
import { getAllServices, getCategories, selectPersonalDashboard, updateSubmissionData } from "../../../../../redux/slices/personalDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../../../assets/icons/error-icon.svg'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

function First({formValid, nameValid, categoryValid, websiteUrlValid,}){
  const dispatch = useDispatch();
  const State = useSelector(selectPersonalDashboard); 
  const {updateSubmissionsData, categoriesData,allServicesData} = State
  const [hasWebsite, setHasWebsite] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isTextFieldFocused, setTextFieldFocus] = useState(false);
  const [isTextMatch, setIsTextMatch] = useState(false);

   // for keybord choise
    const [activeIndex, setActiveIndex] = useState(-1);
    const ulItemRefs = useRef([]);

    const handleServiceNameInput = (field, value, e) => {
    if (value.trim() === '') {
      setSearchResults([]);
    } else {
      const filteredServices = allServicesData?.suggestions.filter((service) =>
        service.name.toLowerCase().includes(value.toLowerCase())
      );

      const isExactMatch = filteredServices.some((service) =>
      service.name.toLowerCase() === value.toLowerCase()
      );
      if (isExactMatch) {
        const exactMatchService = filteredServices.find((service) =>
          service.name.toLowerCase() === value.toLowerCase()
        );
        const serviceId = exactMatchService?.id;
        dispatch(updateSubmissionData({ field: 'service_id', value: serviceId }));
      } else {
        dispatch(updateSubmissionData({ field: 'service_id', value: null }));
      }
     setSearchResults(filteredServices);
    }
    dispatch(updateSubmissionData({ field, value }));
  };

  const handleInput = (field, value) => {
    dispatch(updateSubmissionData({ field, value }));
  };

  const handleResultClick = (result) => {
    handleServiceNameInput('name', result.name);
    dispatch(updateSubmissionData({ field: 'service_id', value: result.id }));
    setSearchResults([]);
    setSelected(result);
    setIsTextMatch(true)
  };

  const setButtonRef = (element, index) => {
    ulItemRefs.current = ulItemRefs.current.filter(Boolean)
    ulItemRefs.current[index] = element
  };

  const handleKeyDown = (e, result) => {
    if (!searchResults.length || !ulItemRefs.current.length) {
      return
    }

    if (e.key === 'ArrowDown') {
      const nextIndex =  (activeIndex + 1) % ulItemRefs.current.length;
      
      setActiveIndex(nextIndex);
      
      ulItemRefs.current[nextIndex]?.focus();

      return
    } 
    
    if (e.key === 'ArrowUp') {
      const prevIndex =  (activeIndex - 1 + ulItemRefs.current.length) % ulItemRefs.current.length;

      setActiveIndex(prevIndex);

      ulItemRefs.current[prevIndex].focus();

      return
    }

    if (e.key === 'Enter' && result?.name) {
      handleResultClick(result);
    }
  }

  const handleTextFieldFocus = () => {
    setTextFieldFocus(true);
  };

  const handleTextFieldBlur = () => {
    setTimeout(() => {
      setTextFieldFocus(false);
    }, 100);
  };

  useEffect(()=>{
    dispatch(getCategories())
  },[])

  useEffect(()=>{
    dispatch(getAllServices())
  },[])

  function webValidation(e){
    setHasWebsite(!hasWebsite);
    // e.currentTarget.previousElement
  }
  return (
    <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px]">
      <div className="flex flex-start">
        <span className="text-primary !text-[18px] !leading-[36px]">
          Tell us about this service
        </span>
      </div>
      <div className="flex flex-col">
        <div>
          <label className="flex flex-start justify-between text-primary !font-semibold mb-[2px]">
            What is the name?
            {
              !nameValid && 
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <TextField
            placeholder={"Service Name"}
            value={updateSubmissionsData.name}
            onChange={(e) => handleServiceNameInput('name', e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={handleTextFieldFocus}
            onBlur={handleTextFieldBlur}
          />
          {searchResults.length > 0 && (
            <div className="relative">
              <ul
                tabIndex={0}
                className="z-10 absolute mt-1 max-h-40 scrollbar-hide w-full overflow-y-scroll rounded-md bg-[#363D50] py-1 text-[#596073] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {searchResults.map((result, i) => (
                  <li
                    tabIndex={i}
                    key={result.id}
                    onClick={() => handleResultClick(result)}
                    ref={(el) => setButtonRef(el, i)}
                    onKeyDown={(e) => handleKeyDown(e, result)}
                    className={`flex items-center gap-2 custom_text relative focus:bg-[#596073] cursor-default outline-none select-none py-2 px-4 text-left transition-colors duration-300 hover:bg-[#596073] ${
                      selected === result ? 'bg-[#596073]' : ''
                    }`}
                  >
                    <img className="w-5 h-5 rounded" src={result.image_url} alt={result.name} />
                    {result.name}
                    {selected === result && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {/* getCategories */}

      <div className="flex flex-col">
        <div>
          <label className=" flex flex-start justify-between text-primary !font-semibold mb-[2px]">
            What type of service is it?
            {
              !categoryValid && 
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <Select
            services={allServicesData}
            category={categoriesData}
            selectedService={selected}
            readOnly={isTextMatch}
          />
        </div>
      </div>

      <div className="flex flex-col">
        <div>
          <label className=" flex flex-start justify-between text-primary !font-semibold mb-[2px]">
            What’s the website homepage?
            {
              !websiteUrlValid && hasWebsite &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <div className={`${!hasWebsite && "disabled opacity-[0.3] pointer-events-none"}`}>
            <TextField value={updateSubmissionsData.website_url} 
            onChange={(e) => handleInput('website_url', e.target.value)} placeholder={"https://"}/>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-[-12px]">
        <div className="flex gap-[5px] items-center">
          <Checkbox onClick={webValidation} name="disableWebsite" className='websiteCheckbox'/>
          <label className="ml-2 flex flex-start text-secondary">
            It doesn’t have a website
          </label>
        </div>
      </div>
    </div>
  );
}

export default First;
