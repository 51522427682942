import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { updatePasswordRequest } from "../../redux/slices/userSlice";

function NewPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inValidAuth, setInvalidAuth] = useState(false);
  const dispatch = useDispatch();
  const newPasswordField = useRef(null);
  const confirmPasswordField = useRef(null);
  const location = useLocation();

  const handleShowNewPassword = () => {
    if (showNewPassword) {
      if (newPasswordField.current && newPasswordField.current.type) {
        newPasswordField.current.type = "password";
      }
      setShowNewPassword(false);
    } else {
      if (newPasswordField.current && newPasswordField.current.type) {
        newPasswordField.current.type = "text";
      }
      setShowNewPassword(true);
    }
  };

  const handleShowConfirmPassword = () => {
    if (showConfirmPassword) {
      if (confirmPasswordField.current && confirmPasswordField.current.type) {
        confirmPasswordField.current.type = "password";
      }
      setShowConfirmPassword(false);
    } else {
      if (confirmPasswordField.current && confirmPasswordField.current.type) {
        confirmPasswordField.current.type = "text";
      }
      setShowConfirmPassword(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword === "") {
      setErrorMessage("* New Password is required");
    } else if (confirmPassword === "") {
      setErrorMessage("* Confirm Password is required");
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("* Passwords do not match");
    } else {
      const token = new URLSearchParams(location.search).get(
        "reset_password_token"
      );

      dispatch(
        updatePasswordRequest({
          reset_password_token: token,
          password: newPassword,
          password_confirmation: newPassword,
        })
      );
      setNewPassword("");
      setConfirmPassword("");
      window.location.href="/success-reset-password";
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-5 sm:px-0"
      style={{
        background: "linear-gradient(129.77deg, #2C3345 26.01%, #323045 84.1%)",
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center justify-center gap-10"
      >
        <div className="flex flex-col items-center gap-4 min-w-[424px] w-[28%]">
          <p className="text-[32px] leading-[20px] font-semibold text-white py-[13px]">
            Create a new password
          </p>
          <p className="text-secondary text-center">
            Password must be at least 8 characters and contain one uppercase
            letter and one symbol.
          </p>
        </div>
        <div className="flex flex-col items-center gap-8 min-w-[424px] w-[28%] bg-[#363D50] rounded-[32px] px-8 py-10">
          <div className="relative w-full">
            <input
              type="password"
              id="new_password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              ref={newPasswordField}
              className={`w-full !bg-transparent rounded-2xl	border border-solid pl-4 pr-[70px] py-3 text-base font-normal text-[#A5AEBC] placeholder:text-base placeholder:font-normal placeholder:text-[#A5AEBC] focus:outline-none ${
                inValidAuth ? "border-[#F25757]" : "border-[#596073]"
              }`}
              placeholder="Set new password"
            />
            {newPassword && (
              <button
                type="button"
                onClick={handleShowNewPassword}
                className="absolute inset-y-0 right-[16px]"
              >
                {!showNewPassword && (
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 4.5C8.61929 4.5 7.5 5.61929 7.5 7C7.5 8.38071 8.61929 9.5 10 9.5C11.3807 9.5 12.5 8.38071 12.5 7C12.5 5.61929 11.3807 4.5 10 4.5ZM8.5 7C8.5 6.17157 9.17157 5.5 10 5.5C10.8284 5.5 11.5 6.17157 11.5 7C11.5 7.82843 10.8284 8.5 10 8.5C9.17157 8.5 8.5 7.82843 8.5 7Z"
                      fill="#939DAD"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0.5C7.99972 0.5 6.14837 1.24366 4.59668 2.19288C3.04317 3.14321 1.76075 4.31738 0.888494 5.22298C-0.0794231 6.22789 -0.0678791 7.77249 0.905285 8.76784C1.78943 9.67215 3.08676 10.8482 4.64188 11.8008C6.19425 12.7517 8.03628 13.5 10 13.5C11.9638 13.5 13.8058 12.7517 15.3582 11.8008C16.9133 10.8482 18.2106 9.67215 19.0948 8.76784C20.0679 7.77249 20.0795 6.22789 19.1115 5.22298C18.2393 4.31738 16.9569 3.14321 15.4034 2.19288C13.8517 1.24366 12.0003 0.5 10 0.5ZM1.60874 5.9167C2.44585 5.04759 3.66215 3.93684 5.11852 3.04593C6.57671 2.1539 8.24638 1.5 10 1.5C11.7537 1.5 13.4233 2.1539 14.8815 3.04593C16.3379 3.93684 17.5542 5.04759 18.3913 5.9167C18.982 6.52995 18.9772 7.45764 18.3797 8.06875C17.5293 8.93853 16.2965 10.0533 14.8358 10.9481C13.3724 11.8445 11.7131 12.5 10 12.5C8.28695 12.5 6.62763 11.8445 5.16423 10.9481C3.70358 10.0533 2.47071 8.93853 1.62032 8.06875C1.02283 7.45764 1.01807 6.52995 1.60874 5.9167Z"
                      fill="#939DAD"
                    />
                  </svg>
                )}
                {showNewPassword && (
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.64645 0.646447C1.84171 0.451184 2.15829 0.451184 2.35355 0.646447L18.3536 16.6464C18.5488 16.8417 18.5488 17.1583 18.3536 17.3536C18.1583 17.5488 17.8417 17.5488 17.6464 17.3536L14.5528 14.2599C13.1854 14.9811 11.6352 15.5 9.99993 15.5C8.03619 15.5 6.19416 14.7517 4.64179 13.8008C3.08668 12.8482 1.78935 11.6721 0.905197 10.7678C-0.0680206 9.77244 -0.0792881 8.22764 0.888592 7.22278C1.74711 6.33148 3.00456 5.1783 4.528 4.2351L1.64645 1.35355C1.45118 1.15829 1.45118 0.841709 1.64645 0.646447ZM5.25585 4.96295C3.73961 5.86643 2.47306 7.01927 1.60882 7.91652C1.0181 8.52981 1.0228 9.4577 1.62023 10.0687C2.47062 10.9385 3.70349 12.0533 5.16414 12.9481C6.62754 13.8445 8.28686 14.5 9.99993 14.5C11.323 14.5 12.6145 14.1091 13.8097 13.5168L11.3787 11.0858C10.9835 11.3475 10.5092 11.5001 10 11.5001C8.61929 11.5001 7.5 10.3808 7.5 9.00011C7.5 8.49101 7.65262 8.01664 7.91429 7.6214L5.25585 4.96295ZM8.64613 8.35323L10.6469 10.354C10.4511 10.4477 10.2319 10.5001 10 10.5001C9.17157 10.5001 8.5 9.82854 8.5 9.00011C8.5 8.76816 8.55239 8.54897 8.64613 8.35323Z"
                      fill="#939DAD"
                    />
                    <path
                      d="M9.05642 3.56181C9.36768 3.52142 9.6824 3.5 9.99993 3.5C11.7536 3.5 13.4232 4.1539 14.8814 5.04593C16.3378 5.93684 17.5541 7.04759 18.3912 7.9167C18.9817 8.52975 18.9767 9.45813 18.379 10.0694C18.027 10.4294 17.6083 10.8326 17.1339 11.2453C16.9256 11.4266 16.9037 11.7424 17.0849 11.9508C17.2662 12.1591 17.582 12.181 17.7903 11.9997C18.2878 11.5669 18.7261 11.1448 19.094 10.7685C20.067 9.77338 20.0796 8.22809 19.1115 7.22298C18.2392 6.31738 16.9568 5.14321 15.4033 4.19288C13.8516 3.24366 12.0002 2.5 9.99993 2.5C9.63736 2.5 9.27961 2.52446 8.92771 2.57013C8.65386 2.60567 8.46068 2.85648 8.49622 3.13033C8.53177 3.40417 8.78257 3.59736 9.05642 3.56181Z"
                      fill="#939DAD"
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
          <div className="relative w-full">
            <input
              type="password"
              id="confirm_password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              ref={confirmPasswordField}
              className={`w-full !bg-transparent rounded-2xl	border border-solid pl-4 pr-[70px] py-3 text-base font-normal text-[#A5AEBC] placeholder:text-base placeholder:font-normal placeholder:text-[#A5AEBC] focus:outline-none ${
                inValidAuth ? "border-[#F25757]" : "border-[#596073]"
              }`}
              placeholder="Confirm new password"
            />
            {confirmPassword && (
              <button
                type="button"
                onClick={handleShowConfirmPassword}
                className="absolute inset-y-0 right-[16px]"
              >
                {!showConfirmPassword && (
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 4.5C8.61929 4.5 7.5 5.61929 7.5 7C7.5 8.38071 8.61929 9.5 10 9.5C11.3807 9.5 12.5 8.38071 12.5 7C12.5 5.61929 11.3807 4.5 10 4.5ZM8.5 7C8.5 6.17157 9.17157 5.5 10 5.5C10.8284 5.5 11.5 6.17157 11.5 7C11.5 7.82843 10.8284 8.5 10 8.5C9.17157 8.5 8.5 7.82843 8.5 7Z"
                      fill="#939DAD"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0.5C7.99972 0.5 6.14837 1.24366 4.59668 2.19288C3.04317 3.14321 1.76075 4.31738 0.888494 5.22298C-0.0794231 6.22789 -0.0678791 7.77249 0.905285 8.76784C1.78943 9.67215 3.08676 10.8482 4.64188 11.8008C6.19425 12.7517 8.03628 13.5 10 13.5C11.9638 13.5 13.8058 12.7517 15.3582 11.8008C16.9133 10.8482 18.2106 9.67215 19.0948 8.76784C20.0679 7.77249 20.0795 6.22789 19.1115 5.22298C18.2393 4.31738 16.9569 3.14321 15.4034 2.19288C13.8517 1.24366 12.0003 0.5 10 0.5ZM1.60874 5.9167C2.44585 5.04759 3.66215 3.93684 5.11852 3.04593C6.57671 2.1539 8.24638 1.5 10 1.5C11.7537 1.5 13.4233 2.1539 14.8815 3.04593C16.3379 3.93684 17.5542 5.04759 18.3913 5.9167C18.982 6.52995 18.9772 7.45764 18.3797 8.06875C17.5293 8.93853 16.2965 10.0533 14.8358 10.9481C13.3724 11.8445 11.7131 12.5 10 12.5C8.28695 12.5 6.62763 11.8445 5.16423 10.9481C3.70358 10.0533 2.47071 8.93853 1.62032 8.06875C1.02283 7.45764 1.01807 6.52995 1.60874 5.9167Z"
                      fill="#939DAD"
                    />
                  </svg>
                )}
                {showConfirmPassword && (
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.64645 0.646447C1.84171 0.451184 2.15829 0.451184 2.35355 0.646447L18.3536 16.6464C18.5488 16.8417 18.5488 17.1583 18.3536 17.3536C18.1583 17.5488 17.8417 17.5488 17.6464 17.3536L14.5528 14.2599C13.1854 14.9811 11.6352 15.5 9.99993 15.5C8.03619 15.5 6.19416 14.7517 4.64179 13.8008C3.08668 12.8482 1.78935 11.6721 0.905197 10.7678C-0.0680206 9.77244 -0.0792881 8.22764 0.888592 7.22278C1.74711 6.33148 3.00456 5.1783 4.528 4.2351L1.64645 1.35355C1.45118 1.15829 1.45118 0.841709 1.64645 0.646447ZM5.25585 4.96295C3.73961 5.86643 2.47306 7.01927 1.60882 7.91652C1.0181 8.52981 1.0228 9.4577 1.62023 10.0687C2.47062 10.9385 3.70349 12.0533 5.16414 12.9481C6.62754 13.8445 8.28686 14.5 9.99993 14.5C11.323 14.5 12.6145 14.1091 13.8097 13.5168L11.3787 11.0858C10.9835 11.3475 10.5092 11.5001 10 11.5001C8.61929 11.5001 7.5 10.3808 7.5 9.00011C7.5 8.49101 7.65262 8.01664 7.91429 7.6214L5.25585 4.96295ZM8.64613 8.35323L10.6469 10.354C10.4511 10.4477 10.2319 10.5001 10 10.5001C9.17157 10.5001 8.5 9.82854 8.5 9.00011C8.5 8.76816 8.55239 8.54897 8.64613 8.35323Z"
                      fill="#939DAD"
                    />
                    <path
                      d="M9.05642 3.56181C9.36768 3.52142 9.6824 3.5 9.99993 3.5C11.7536 3.5 13.4232 4.1539 14.8814 5.04593C16.3378 5.93684 17.5541 7.04759 18.3912 7.9167C18.9817 8.52975 18.9767 9.45813 18.379 10.0694C18.027 10.4294 17.6083 10.8326 17.1339 11.2453C16.9256 11.4266 16.9037 11.7424 17.0849 11.9508C17.2662 12.1591 17.582 12.181 17.7903 11.9997C18.2878 11.5669 18.7261 11.1448 19.094 10.7685C20.067 9.77338 20.0796 8.22809 19.1115 7.22298C18.2392 6.31738 16.9568 5.14321 15.4033 4.19288C13.8516 3.24366 12.0002 2.5 9.99993 2.5C9.63736 2.5 9.27961 2.52446 8.92771 2.57013C8.65386 2.60567 8.46068 2.85648 8.49622 3.13033C8.53177 3.40417 8.78257 3.59736 9.05642 3.56181Z"
                      fill="#939DAD"
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
          <div className="w-full flex flex-col items-center gap-4">
            <button
              type="submit"
              className="w-full rounded-2xl bg-[#FFD74A] backdrop-blur-[16px] px-[22px] py-[14px] text-sm leading-[21px] font-bold text-black focus:outline-none"
            >
              Reset password
            </button>
            <Link to="/login" className="flex items-center gap-2 py-1.5">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.2771 4.29434C16.7457 4.76297 16.7457 5.52277 16.2771 5.9914L10.4828 11.7857L16.2771 17.5801C16.7457 18.0487 16.7457 18.8085 16.2771 19.2771C15.8085 19.7457 15.0487 19.7457 14.58 19.2771L8.78571 13.4828C7.84845 12.5455 7.84845 11.0259 8.78571 10.0887L14.58 4.29434C15.0487 3.82571 15.8085 3.82571 16.2771 4.29434Z"
                  fill="#FFD74A"
                />
              </svg>
              <span className="text-sm font-bold leading-[21px] text-[#FFD74A]">
                Back to log in
              </span>
            </Link>
          </div>
          {errorMessage && (
            <p className="text-sm font-medium text-red-400">{errorMessage}</p>
          )}
        </div>
      </form>
    </div>
  );
}

export default NewPassword;
