import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ExpensesTableBody from "./tableBodies/expensesTableBody";
import ExpensesDetailsTableBody from "./tableBodies/expensesDetailsTableBody";
import UserExpensesTableBody from "./tableBodies/userExpensesTableBody";
import SubmissionTableBody from "./tableBodies/submissionTableBody";
import RequestTableBody from "./tableBodies/requestTableBody";
import TeamTableBody from "./tableBodies/teamTableBody";
import ServiceTableBody from "./tableBodies/serviceTableBody";
import UserRequestsTableBody from "./tableBodies/userRequestsTableBody";
import ServiceRequestTableBody from "./tableBodies/serviceRequestTableBody";
import EmployeeTableBody from "./tableBodies/employeeTableBody";
import Unicon from "../../assets/images/arrow-down-circle.svg";
import ServiceSubmisionTableBody from "./tableBodies/serviceSubmissionTableBody";
import ServiceDetailsTableBody from "./tableBodies/serviceDetailsTableBody";
import PlanUsageTableBody from "./tableBodies/planusageTableBody";
import PlanUsageDetailsTableBody from "./tableBodies/planusageDetailsTableBody";
import Button from "../Buttons/button";
import cn from "classnames";

function Table({
  userId,
  teamService,
  fullName,
  setFullName,
  orderType,
  headings,
  tableData,
  id,
  table,
  setState,
  setId,
  setDataPlanId,
  setPopup,
  show,
  setShow,
  model,
  setReceiptData,
  setManagerId,
  handleNameChange,
  handleServicename,
  handleServicetype,
  handleServicecategory,
  handleServiceexpensed,
  handleServicewebsite,
  handleServicesubmitdate,
  handleUser,
  handleUserRating,
  handleFeedbackGiven,
  handleRequestMade,
  handleUserMonthlyCost,
  handleServiceName,
  handleMonthlyCost,
  handleRating,
  handleContractEnd,
  handleUserCount,
  handleUserCostAvarage,
  handleRequestType,
  handleStatus,
  handleService,
  handleRequestDate,
  handleRequestReason,
  handleComment,
  handleEmployeeName,
  handleEmpMonthlyCost,
  handleEmpService,
  handleEmpSubmission,
  handleEmpRequest,
  handleEmpFeedback,
  handleTeamName,
  handleTeamMonthlyCost,
  handleTeamService,
  handleTeamCost,
  // handleTeamSubmission,
  // handleTeamRequest,
  // handleTeamFeedback,
  handleServiceSubName,
  handleServiceCategory,
  handleTotalSubmission,
  handleMissingSubmission,
  handleSuggested,
  handleExpensed,
  handleIsService,
  handleServiceDetails,
  handleSubmissionTypeDetails,
  handleCategoryDetails,
  handleExpensedDetails,
  handleWebsiteDetails,
  hadleRequestDateDetails,
  handleExpensesCategory,
  handleExpensesInstances,
  handleExpensesCost,
  handleExpenseName,
  handleExpenseEmployee,
  handleExpenseDetailCost,
  handleExpenseTransactionDate,
  handleExpensedDate,
  handleExpenseReceipt,
  handlePlanName,
  handlePlansUsed,
  handleRoamingTotals,
  handleOverageTotals,
  handleOtherCosts,
  handleContractualCost,
  handlePlanUser,
  handlePlanTotalCost,
  handlePlanDataUsed,
  handlePlanMinutesUsed,
  handlePlanRoamingUsed,
}) {
  const [currentSelectedType, setCurrentSelectedType] = useState("");

  const handleSubmission = {
    Service: handleServicename,
    Type: handleServicetype,
    Category: handleServicecategory,
    Expensed: handleServiceexpensed,
    Website: handleServicewebsite,
    "Submission date": handleServicesubmitdate,
  };
  const handlerService = {
    Service: handleServiceName,
    "Impact rating": handleRating,
    "Users #": handleUserCount,
    "Average user cost": handleUserCostAvarage,
    Cost: handleMonthlyCost,
    "Contract end": handleContractEnd,
  };
  const handlerRequest = {
    Service: handleService,
    "Request Type": handleRequestType,
    Status: handleStatus,
    Comments: handleComment,
    "Request date": handleRequestDate,
    Reason: handleRequestReason,
  };
  const handleUserRequest = {
    User: handleUser,
    Rating: handleUserRating,
    "Monthly cost": handleUserMonthlyCost,
    "Service requests": handleRequestMade,
    "Feedback given": handleFeedbackGiven,
  };
  const handleTeam = {
    Employee: handleTeamName,
    "Monthly cost": handleTeamMonthlyCost,
    Services: handleTeamService,
    "Team Cost": handleTeamCost,
    // Submissions: handleTeamSubmission,
    // Requests: handleTeamRequest,
    // Feedback: handleTeamFeedback,
  };
  const handleEmployee = {
    Employee: handleEmployeeName,
    "Monthly cost": handleEmpMonthlyCost,
    Services: handleEmpService,
    Submissions: handleEmpSubmission,
    Requests: handleEmpRequest,
    Feedback: handleEmpFeedback,
  };
  const handleServiceSubmission = {
    Service: handleServiceSubName,
    Categories: handleServiceCategory,
    "Total Submission": handleTotalSubmission,
    "Missing #": handleMissingSubmission,
    "Suggested #": handleSuggested,
    "Expensed #": handleExpensed,
    "Service is Setup": handleIsService,
  };

  const handeServiceDetails = {
    Service: handleServiceDetails,
    Type: handleSubmissionTypeDetails,
    Category: handleCategoryDetails,
    Expensed: handleExpensedDetails,
    Website: handleWebsiteDetails,
    "Request date": hadleRequestDateDetails,
  };

  const handeExpenses = {
    Category: handleExpensesCategory,
    Instances: handleExpensesInstances,
    Cost: handleExpensesCost,
  };

  const handeExpensesDetails = {
    Expense: handleExpenseName,
    Employee: handleExpenseEmployee,
    Cost: handleExpenseDetailCost,
    "Transaction date": handleExpenseTransactionDate,
    "Expensed date": handleExpensedDate,
    Receipt: handleExpenseReceipt,
  };

  const handeUserExpenses = {
    Expense: handleExpenseName,
    "Expense category": handleExpensesCategory,
    Cost: handleExpenseDetailCost,
    "Transaction date": handleExpenseTransactionDate,
    "Expensed date": handleExpensedDate,
    Receipt: handleExpenseReceipt,
  };

  const handePlanUsage = {
    Plan: handlePlanName,
    "Plans used": handlePlansUsed,
    "Roaming totals": handleRoamingTotals,
    "Overage totals": handleOverageTotals,
    "Other costs": handleOtherCosts,
    "Contractual vs actual cost": handleContractualCost,
  };

  const handePlanUsageDetails = {
    User: handlePlanUser,
    "Total cost": handlePlanTotalCost,
    "Data used": handlePlanDataUsed,
    "Minutes used": handlePlanMinutesUsed,
    "Roaming used": handlePlanRoamingUsed,
  };

  return (
    <div
      className={`rounded-t-xl pb-[8px] w-[100%] ${
        table === "expenses" ? "px-[24px]" : "px-[8px]"
      }`}
    >
      <div
        className={`scrollbar-hide ${
          table === "expensesDetails" || table === "userExpenses"
            ? "!overflow-y-visible"
            : "overflow-x-auto"
        }`}
      >
        <table className="table-auto w-[100%]">
          <thead
            className={`sticky top-0 bg-[#363D50] ${
              table === "serviceDetails" ? "thead-border" : ""
            } ${table === "expenses" ? "table-head-new !relative" : "table-head"}`}
          >
            <tr>
              {tableData &&
                headings.map((item, index) => (
                  <th
                    key={index}
                    className={`text-left text-secondary border-[#434A5D] py-[8px]`}
                    onClick={() => {
                      const handler =
                        table === "employee"
                          ? handleEmployee[item]
                          : table === "team"
                          ? handleTeam[item]
                          : table === "serviceSubmission"
                          ? handleServiceSubmission[item]
                          : table === "submission"
                          ? handleSubmission[item]
                          : table === "service"
                          ? handlerService[item]
                          : table === "request"
                          ? handlerRequest[item]
                          : table === "userRequests"
                          ? handleUserRequest[item]
                          : table === "serviceDetails"
                          ? handeServiceDetails[item]
                          : table === "expenses"
                          ? handeExpenses[item]
                          : table === "expensesDetails"
                          ? handeExpensesDetails[item]
                          : table === "userExpenses"
                          ? handeUserExpenses[item]
                          : table === "planUsage"
                          ? handePlanUsage[item]
                          : table === "planUsageDetails"
                          ? handePlanUsageDetails[item]
                          : null;
                      setCurrentSelectedType(item);
                      handler && handler(orderType, false);
                    }}
                  >
                    {item === "" ? (
                      item
                    ) : (
                      <Button
                        className="sorting_btn_wrapper"
                        secondaryHoverOutlined={true}
                        outlined={true}
                      >
                        <span className="flex items-center text-[#A5AEBC]">
                          {item}
                          <img
                            className={cn("sortingicon", {
                              "rotate-180":
                                currentSelectedType === item &&
                                orderType === "desc",
                            })}
                            src={Unicon}
                            alt="icon"
                          />
                        </span>
                      </Button>
                    )}
                  </th>
                ))}
              {!tableData &&
                headings.map((item, index) => (
                  <SkeletonTheme
                    key={index}
                    baseColor="#3B4255"
                    highlightColor="#0000001a"
                  >
                    <th
                      key={index}
                      className={`text-left text-secondary border-[#434A5D] px-3 py-[20px] ${
                        index === 0 ? "w-[212px]" : ""
                      }`}
                    >
                      <Skeleton />
                    </th>
                  </SkeletonTheme>
                ))}
            </tr>
          </thead>

          {table === "expenses" && (
            <ExpensesTableBody
              setState={setState}
              setId={setId}
              setPopup={setPopup}
              tableData={tableData}
            />
          )}
          {table === "submission" && (
            <SubmissionTableBody
              setState={setState}
              setId={setId}
              setPopup={setPopup}
              tableData={tableData}
            />
          )}
          {table === "request" && (
            <RequestTableBody
              setState={setState}
              setId={setId}
              id={id}
              setPopup={setPopup}
              tableData={tableData}
            />
          )}
          {table === "team" && (
            <TeamTableBody
              tableData={tableData}
              fullName={fullName}
              setFullName={setFullName}
              setManagerId={setManagerId}
              handleNameChange={handleNameChange}
            />
          )}
          {table === "service" && (
            <ServiceTableBody
              tableData={tableData}
              teamService={teamService}
              userId={userId}
            />
          )}
          {table === "employee" && (
            <EmployeeTableBody
              tableData={tableData}
              setManagerId={setManagerId}
            />
          )}
          {table === "serviceSubmission" && (
            <ServiceSubmisionTableBody tableData={tableData} />
          )}
          {table === "userRequests" && (
            <UserRequestsTableBody tableData={tableData} />
          )}
          {table === "serviceRequests" && (
            <ServiceRequestTableBody tableData={tableData} />
          )}
          {table === "serviceDetails" && (
            <ServiceDetailsTableBody
              setState={setState}
              setId={setId}
              id={id}
              setPopup={setPopup}
              tableData={tableData}
              show={show}
              setShow={setShow}
              model={model}
            />
          )}
          {table === "expensesDetails" && (
            <ExpensesDetailsTableBody
              setState={setState}
              setId={setId}
              setPopup={setPopup}
              tableData={tableData}
              setReceiptData={setReceiptData}
              show={show}
              setShow={setShow}
            />
          )}
          {table === "userExpenses" && (
            <UserExpensesTableBody
              setState={setState}
              setId={setId}
              setPopup={setPopup}
              tableData={tableData}
              show={show}
              setShow={setShow}
              setReceiptData={setReceiptData}
            />
          )}
          {table === "planUsage" && (
            <PlanUsageTableBody
              setState={setState}
              setDataPlanId={setDataPlanId}
              setPopup={setPopup}
              tableData={tableData}
            />
          )}
          {table === "planUsageDetails" && (
            <PlanUsageDetailsTableBody tableData={tableData} />
          )}
        </table>
      </div>
    </div>
  );
}
export default Table;
