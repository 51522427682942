import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Select from "../../../../Select/select";
import TextField from "../../../../TextField/TextField";
import {getInitializeServicesData, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../../../assets/icons/error-icon.svg';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { current } from "@reduxjs/toolkit";

function First({formValidService, formValidOwner, onServiceIdChange, onServiceNameChange, onCategoryChange, onOwnerChange}){
  const dispatch = useDispatch();
  const State  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData} = State
  const [hasWebsite, setHasWebsite] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchServices, setSearchServices] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [serviceText, setServiceText] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [isTextFieldFocused, setTextFieldFocus] = useState(false);
  const [isTextMatch, setIsTextMatch] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [servicesActiveIndex, setServicesActiveIndex] = useState(-1);
  const ulItemRefs = useRef([]);
  const servicesUlItemRefs = useRef([]);

  useEffect(()=>{
    dispatch(getInitializeServicesData())
  },[])

  const handleServiceNameInput = (field, value, e) => {
    if (value.trim() === '') {
      setServiceText("")
      onServiceNameChange("");
      setSearchServices([]);
    } else {
      setServiceText(value);
      const filteredServices = CategoriesAndUsersData?.suggestions.filter((service) =>
        service.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchServices(filteredServices);
      setServiceName(value);
      onServiceNameChange(value);
    }
  };

  const handleUserNameInput = (field, value, e) => {
    if (value.trim() === '') {
      setSearchText("")
      onOwnerChange("");
      setSearchResults([]);
    } else {
      setSearchText(value);

      const filteredUsers = CategoriesAndUsersData?.company_users.filter((user) => {
        const searchTerm = value.toLowerCase();
        return (
          user.name.toLowerCase().includes(searchTerm) ||
          user.email.toLowerCase().includes(searchTerm) ||
          user.id.toString().includes(searchTerm)
        );
      });
      setSearchResults(filteredUsers);
      // setServiceName(value);
      onOwnerChange(value);
    }
  };

  const handleResultClick = (result) => {
    handleUserNameInput('name', result.name);
    setSearchResults([]);
    setSelected(result);
    setIsTextMatch(true);
    onOwnerChange(result.name);
  };

  const handleServiceResultClick = (result) => {
    handleServiceNameInput('name', result.name);
    setSearchServices([]);
    setSelectedService(result);
    setServiceName(result.name);
    onServiceIdChange(result.id);
  };

  const handleCategoryChange = (selectedCategory) => {
    onCategoryChange(selectedCategory);
  };

  const setButtonRef = (element, index) => {
    ulItemRefs.current = ulItemRefs.current.filter(Boolean)
    ulItemRefs.current[index] = element
  };

  const setButtonRefForServices = (element, index) => {
    servicesUlItemRefs.current = servicesUlItemRefs.current.filter(Boolean)
    servicesUlItemRefs.current[index] = element
  }

  const handleKeyDownOnServiceField = (e, result) => {
    if (!searchServices.length || !servicesUlItemRefs.current.length) {
      return
    }

    if (e.key === 'ArrowDown') {
      const nextIndex =  (servicesActiveIndex + 1) % servicesUlItemRefs.current.length;
      setServicesActiveIndex(nextIndex);
      servicesUlItemRefs.current[nextIndex]?.focus();

      return
    }

    if (e.key === 'ArrowUp') {
      const prevIndex =  (servicesActiveIndex - 1 + servicesUlItemRefs.current.length) % servicesUlItemRefs.current.length;

      setServicesActiveIndex(prevIndex);

      servicesUlItemRefs.current[prevIndex]?.focus();

      return
    }

    if (e.key === 'Enter' && result?.name) {
      handleServiceResultClick(result);
    }
  }

  const handleKeyDown = (e, result) => {
    if (!searchResults.length || !ulItemRefs.current.length) {
      return
    }

    if (e.key === 'ArrowDown') {
      const nextIndex =  (activeIndex + 1) % ulItemRefs.current.length;
      setActiveIndex(nextIndex);
      ulItemRefs.current[nextIndex]?.focus();

      return
    }

    if (e.key === 'ArrowUp') {
      const prevIndex =  (activeIndex - 1 + ulItemRefs.current.length) % ulItemRefs.current.length;

      setActiveIndex(prevIndex);

      ulItemRefs.current[prevIndex]?.focus();

      return
    }

    if (e.key === 'Enter' && result?.name) {
      handleResultClick(result);
    }
  };

  const handleTextFieldFocus = () => {
    setTextFieldFocus(true);
  };

  const handleTextFieldBlur = () => {
    setTimeout(() => {
      setTextFieldFocus(false);
      // setSearchServices([]);
    }, 100);
  };

  return (
    <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px] addServiceForm">
      <div className="flex flex-start">
      </div>
      <div className="flex flex-col">
        <div>
          <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            Display name?
            {
              !formValidService &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <TextField
            placeholder={"Enter service name"}
            value={serviceText}
            onChange={(e) => handleServiceNameInput('name', e.target.value,e)}
            onKeyDown={(e) => handleKeyDownOnServiceField(e)}
            onFocus={handleTextFieldFocus}
            onBlur={handleTextFieldBlur}
          />
          {searchServices.length > 0 && (
            <div
              className="relative"
            >
              <ul
                tabIndex={0}
                className="z-10 absolute mt-1 max-h-40 scrollbar-hide w-full overflow-y-scroll rounded-md bg-[#363D50] py-1 text-[#596073] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {searchServices.map((result, i) => (
                  <li
                    tabIndex={i}
                    ref={(el) => setButtonRefForServices(el, i)}
                    key={result.id}
                    onKeyDown={(e) => handleKeyDownOnServiceField(e, result)}
                    onClick={() => handleServiceResultClick(result)}
                    className={`flex items-center gap-2 custom_text relative cursor-default focus:bg-[#596073] outline-none select-none py-2 pl-10 pr-4 text-left transition-colors duration-300 hover:bg-[#596073] ${
                      selectedService === result ? 'bg-[#596073]' : ''
                    }`}
                  >
                    <img className="w-5 h-5 rounded" src={result.image_url} alt={result.name} />
                    {result.name}
                    {selectedService === result && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          
        </div>
      </div>
      {/* getCategories */}

      <div className="flex flex-col">
        <div>
          <label className=" flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            Category
            {/* {
              !formValid && 
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }*/}
          </label>
          <Select
            // services={}
            category={CategoriesAndUsersData}
            onCategoryChange={handleCategoryChange}
            selectedService={selectedService}
            // readOnly={}
          />
        </div>
      </div>

      <div className="flex flex-col">
        <div>
          <label className=" flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            Service owner
            {
              !formValidOwner &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <div className="relative">
            <TextField
              placeholder={"Name, email, or employee ID"}
              value={searchText}
              onChange={(e) => handleUserNameInput('name', e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
              // onFocus={handleTextFieldFocus}
              // onBlur={handleTextFieldBlur}
            />
            <span className="text-secondary">
              Owners can be changed at any time from a services “service members” list. 
            </span>

            {searchResults.length > 0 && (
              <div
                className="z-10 absolute mt-[-46px] w-full"
              >
                <ul
                  tabIndex={0}
                  className="max-h-36 scrollbar-hide w-full overflow-y-scroll rounded-md bg-[#363D50] py-1 text-[#596073] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {searchResults.map((result, i) => (
                    <li
                      tabIndex={i}
                      ref={(el) => setButtonRef(el, i)}
                      key={result.id}
                      onKeyDown={(e) => handleKeyDown(e, result)}
                      onClick={() => handleResultClick(result)}
                      className={`custom_text relative cursor-default focus:bg-[#596073] outline-none select-none py-2 pl-10 pr-4 text-left transition-colors duration-300 hover:bg-[#596073] ${
                        selected === result ? 'bg-[#596073]' : ''
                      }`}
                    >
                      <div className="flex flex-col">
                        <span className="text-primary">{result.name}</span>
                        <span className="text-secondary">{result.distinction}</span>
                      </div>

                      {selected === result && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default First;
