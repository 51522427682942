import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import fileUpload from "../../../../assets/images/fileUpload.png";
import successImage from "../../../../assets/images/tag-expensed.png";

const fileTypes = ["XLSX", "PDF"];

function DragDrop({ 
    setFile,
    contract_file,
    setUploadPersentage,
    uploadPersentage,
    isUploading,
    setIsUploading,
    uploadSuccess,
    setUploadSuccess,
    error,
    setError,
  }) {
  const [fileName, setFileName] = useState(null)
  const [uploadInterval, setUploadInterval] = useState(null);

  const handleChange = (file) => {
    setIsUploading(true);
    setFileName(file.name)
    if (file) {
      setTimeout(() => {
        setFile(file);
      }, 2000);
      simulateUploadProgress();
      setError((prevError) => ({ ...prevError, contract_file: false }));
    }
  };
  // Function to simulate the upload progress
  const simulateUploadProgress = () => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadPersentage(progress);
      if (progress >= 100) {
        clearInterval(interval);
        // setIsUploading(false);
        setUploadSuccess(true);
      }
    }, 200);
    setUploadInterval(interval);
  };

  const handleCancel = () => {
    if (uploadInterval) {
      clearInterval(uploadInterval);
    }
    setIsUploading(false);
    setUploadPersentage(0);
    setFileName(null); // Clear file
  };

  const handleRemove = () => {
    setFileName(null);
    setUploadPersentage(0);
    setIsUploading(false);
    setUploadSuccess(false);
  };

  return (
    <div className="customDragDrop">
      <div className="ManageDragDropContainer">
      <div className={`h-39 w-full border-2 border-dotted ${ error?.contract_file ? "border-red-500" : "border-[#596073]"} rounded-2xl p-6 flex flex-col items-center justify-center`}>
        { (!uploadSuccess && !isUploading) && <img src={fileUpload} alt="icon" className="DragDropImg" /> }
        {isUploading && <div className="w-10 h-10 border-4 border-[#FFD74A] border-t-transparent border-solid rounded-full animate-spin"></div>}
        {uploadSuccess && <img src={successImage} alt="icon" className="DragDropImg" />}  
        <div className="DragDropHeading">
          {(!uploadSuccess && !isUploading) && "Drag and drop Excel file"}
          {isUploading && `Uploading... ${uploadPersentage}%`}
          {uploadSuccess && "Upload complete!"}
        </div>
        <div className="fileText">
          <span>
            {(!uploadSuccess && !isUploading) && "or"}
            {(uploadSuccess && contract_file?.name) ||(uploadSuccess && contract_file?.filename)}
          </span>
          <span className="selectText">
            {(!uploadSuccess && !isUploading) && "Select a file"}
            { isUploading && <button onClick={handleCancel}>Cancel</button> }
            { uploadSuccess && <button onClick={handleRemove}>Remove</button> }    
          </span>
        </div>
      </div>
      { (!isUploading && !uploadSuccess) && (
        <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
      )}
      </div>
    </div>
  );
}

export default DragDrop;