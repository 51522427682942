import React from "react";
import { useFormattedAmount } from "../../../../../hooks/useFormattedAmount";

function PlanUsageCard({dataPlans}) {
  const formattedAmount = useFormattedAmount();
  const monthName = dataPlans?.[0]?.billing_period_month || "";
  return (
    <>
      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-4 gap-[8px] max-w-[100%] h-[fit-content]">
        <div className="flex items-center pt-[24px] px-[24px] pb-[16px] gap-[16px] w-full">
          <div className="flex justify-center items-center bg-[#242B3E] p-2 rounded-[16px] w-[48px] h-[48px]">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4075 6.11682C17.762 6.82099 17.2444 7.71932 16.8999 8.66634H21.4113C21.9297 8.65388 22.3388 8.51779 22.6441 8.29498C22.9604 8.06414 23.2152 7.70411 23.3538 7.16657C23.6719 5.93285 22.8697 4.66634 21.3327 4.66634C20.2375 4.66634 19.2373 5.21158 18.4075 6.11682ZM15.1642 8.66634C14.7975 7.71423 14.2804 6.81631 13.6444 6.1139C12.8247 5.20854 11.8476 4.66634 10.7555 4.66634C9.21853 4.66634 8.41634 5.93285 8.73442 7.16657C8.87302 7.70411 9.12785 8.06414 9.44414 8.29498C9.74942 8.51779 10.1585 8.65388 10.6769 8.66634H15.1642ZM16.026 7.31046C16.3941 6.546 16.8643 5.82706 17.4246 5.21586C18.4281 4.1211 19.7612 3.33301 21.3327 3.33301C23.7957 3.33301 25.1667 5.47542 24.6449 7.49945C24.5315 7.93932 24.3549 8.33174 24.1187 8.66844C25.9044 8.73131 27.3327 10.1987 27.3327 11.9997V14.6663C27.3327 16.0332 26.51 17.2079 25.3327 17.7223V22.6663C25.3327 24.5073 23.8403 25.9997 21.9993 25.9997H9.99935C8.1584 25.9997 6.66602 24.5073 6.66602 22.6663V17.7223C5.48875 17.2079 4.66602 16.0332 4.66602 14.6663V11.9997C4.66602 10.1692 6.14154 8.68326 7.9681 8.66648C7.73259 8.33021 7.5565 7.93847 7.44331 7.49945C6.92147 5.47542 8.29254 3.33301 10.7555 3.33301C12.3302 3.33301 13.6416 4.12414 14.6328 5.21902C15.1866 5.83065 15.6528 6.54893 16.026 7.31046ZM10.6627 9.99967H7.99935C6.89478 9.99967 5.99935 10.8951 5.99935 11.9997V14.6663C5.99935 15.7709 6.89478 16.6663 7.99935 16.6663H15.3327V9.99967H10.7555V10.0006C10.7245 10.0006 10.6935 10.0003 10.6627 9.99967ZM21.3327 10.0006V9.99967H16.666V16.6663H23.9993C25.1039 16.6663 25.9993 15.7709 25.9993 14.6663V11.9997C25.9993 10.8951 25.1039 9.99967 23.9993 9.99967H21.4255C21.3947 10.0003 21.3637 10.0006 21.3327 10.0006ZM16.666 17.9997H23.9993V22.6663C23.9993 23.7709 23.1039 24.6663 21.9993 24.6663H16.666V17.9997ZM15.3327 17.9997V24.6663H9.99935C8.89478 24.6663 7.99935 23.7709 7.99935 22.6663V17.9997H15.3327Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="flex flex-start flex-col gap-[2px]">
            <div className="text-primary !text-[16px] !font-bold !leading-[24px]">
              Monthly plan usage({monthName})
            </div>
            <span className="text-secondary !text-[14px] !font-light !leading-[20px]">
              Your usage data for this service
            </span>
          </div>
        </div>
        <div className="px-8">
          <div className="h-px bg-[#434A5D]"></div>
          { dataPlans?.map((item, index) =>(
            <div key={index} className="mt-6">
              <div className="flex items-center gap-1">
                <p className="text-white text-sm font-normal">{item.name}</p>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 11.9998V12.0073M9 9.74982C9.33731 9.75084 9.66512 9.63813 9.93049 9.4299C10.1959 9.22166 10.3833 8.93007 10.4625 8.60219C10.5418 8.27432 10.5082 7.9293 10.3672 7.62287C10.2262 7.31643 9.98605 7.06646 9.6855 6.91332C9.38713 6.76048 9.04583 6.71309 8.71711 6.77886C8.38839 6.84463 8.09159 7.0197 7.875 7.27557"
                    stroke="#A5AEBC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z"
                    stroke="#A5AEBC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="flex items-center justify-between gap-7 pt-6 pb-8">
                <div className="h-[91px]">
                  <p className="!text-sm !font-medium text-secondary">
                    <span className="!text-[32px] !font-bold !leading-[48px] text-white">
                      {item.data_used_in_plan}
                    </span>
                    <span className="text-white !font-bold">GB</span>/{item.data_available_in_plan}GB
                  </p>
                  <p className="text-secondary">Data used</p>
                </div>
                <div className="w-px h-[32px] bg-[#596073]"></div>
                <div className="h-[91px]">
                  <p className="!text-sm !font-medium text-secondary">
                    <span className="!text-[32px] !font-bold !leading-[48px] text-white">
                      {item.minutes_used_in_plan}
                    </span>
                    <span className="text-white !font-bold">m</span>/{item.minutes_available_in_plan}m
                  </p>
                  <p className="text-secondary">Minutes used</p>
                </div>
                <div className="w-px h-[32px] bg-[#596073]"></div>
                <div className="h-[91px]">
                  <p className="!text-sm !font-medium text-secondary">
                    <span className="!text-[32px] !font-bold !leading-[48px] text-[#F25757]">
                      {item.minutes_used_roaming}
                    </span>
                    <span className="text-white !font-bold">m</span>/{item.minutes_available_roaming}m
                  </p>
                  <p className="text-secondary">Roaming used</p>
                </div>
                <div className="w-px h-[32px] bg-[#596073]"></div>
                <div className="h-[91px]">
                  <p className="!text-[32px] !font-bold !leading-[48px] text-white">
                    {formattedAmount(item.subscription_cost)}
                  </p>
                  <p className="text-secondary">Subscription cost</p>
                </div>
                <div className="w-px h-[32px] bg-[#596073]"></div>
                <div className="h-[91px]">
                  <p className="!text-[32px] !font-bold !leading-[48px] text-white">
                    {formattedAmount(item.total_monthly_cost)}
                  </p>
                  <p className="text-secondary">
                    Total cost
                    <br />
                    (subscription + fees)
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PlanUsageCard;
